import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import PillButton from './PillButton';

interface ISearchResetButton {
  handleReset: (event) => void;
  disabled?: boolean;
}

const SearchResetButton = ({ handleReset, disabled }: ISearchResetButton) => (
  <PillButton
    type="ghost"
    icon={<CloseOutlined />}
    disabled={disabled}
    onClick={handleReset}
    data-testid="search-reset-button"
  >
    Reset
  </PillButton>
);

export default SearchResetButton;
