import React, { useCallback, useState } from 'react';

import { Select, SelectProps } from 'antd';

import useSearchPartner from '../features/lp-village/partners/hooks/useSearchPartner';

interface PartnerAutocompleteProps {
  reactKey?: string | number;
  placeholder?: string;
  style?: {};

  uptdateSearch: Function;
}

const PartnerAutocomplete = ({
  reactKey,
  placeholder,
  style,
  uptdateSearch,
}: PartnerAutocompleteProps) => {
  const { getMultiplePartner } = useSearchPartner();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectMessage, setSelectMessage] = useState<string>('');

  const [partnerOptions, setPartnerOptions] = useState<SelectProps['options']>(
    []
  );
  const [currentSelectedValue, setCurrentSelectedValue] = useState<
    SelectProps['options']
  >([]);

  const handlePartnerSearch = useCallback(
    (searchValue: string) => {
      if (searchValue.length > 2) {
        // setIsLoading(true);

        getMultiplePartner(searchValue)
          .then(({ partners }) => {
            if (partners.length) {
              const options = partners.map((partner) => ({
                label: partner.name,
                value: partner.id,
              }));

              // merge current value selected whith new value filter and remove duplicate
              const mergeOption = [...options, ...currentSelectedValue!].filter(
                (opt, index, array) =>
                  index === array.findIndex((item) => item.value === opt.value)
              );

              setPartnerOptions(mergeOption);
              setIsLoading(false);
            }
          })
          .catch(() => {
            setSelectMessage(
              'No result or research returns too many values. Please be more specific'
            );
          });
      }
    },
    [currentSelectedValue, getMultiplePartner]
  );

  const handleUpdate = useCallback(
    (selectedValues) => {
      let partnerSelectedIds: any = [];
      if (currentSelectedValue) {
        partnerSelectedIds = selectedValues?.map((opt) => opt.value);
      }

      uptdateSearch(partnerSelectedIds);
    },
    [currentSelectedValue, uptdateSearch]
  );

  return (
    <Select
      key={reactKey}
      mode="multiple"
      allowClear
      placeholder={placeholder}
      style={style}
      options={partnerOptions}
      maxTagCount={1}
      filterSort={(a, b) => {
        if (a.label! < b.label!) {
          return -1;
        }
        if (a.label! > b.label!) {
          return 1;
        }

        return 0;
      }}
      labelInValue
      onSearch={(newValue) => handlePartnerSearch(newValue)}
      onSelect={(_, option) => {
        setCurrentSelectedValue(
          currentSelectedValue?.concat({
            label: option.label,
            value: option.value,
          })
        );
      }}
      onChange={(v) => {
        handleUpdate(v);
      }}
      onBlur={handleUpdate}
      loading={isLoading}
      showSearch
      filterOption={false}
      optionFilterProp="value"
      notFoundContent={<p>{selectMessage}</p>}
    />
  );
};
export default PartnerAutocomplete;
