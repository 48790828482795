import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';
interface ISearchButton {
  loading?: boolean;
}

const SearchButton = ({ loading }: ISearchButton) => (
  <AntdButton
    type="primary"
    icon={<SearchOutlined />}
    shape="round"
    loading={loading}
    htmlType="submit"
    data-testid="search-filter-button"
  >
    Search
  </AntdButton>
);

export default SearchButton;
